import { GatsbyImage, getImage, IGatsbyImageData } from 'gatsby-plugin-image';
import React, { useEffect, useState } from 'react';

interface ImageProp {
	full: IGatsbyImageData;
	thumb: IGatsbyImageData;
	thumbAlt?: string;
	title?: string;
	relativeDirectory?: string;
}
interface GalleryProps {
	images: ImageProp[];
	filter: string;
}

const Gallery: React.FC<GalleryProps> = ({ images = [], filter }) => {
	const [isOpen, setIsOpen] = useState(false);
	const [selectedIndex, setSelectedIndex] = useState<number | undefined>();
	const [isMobile, setIsMobile] = useState(false);
	const [mainSrc, setMainSrc] = useState<IGatsbyImageData | undefined>();
	const [loading, setLoading] = useState(false);

	// URLs for full width images
	const getMainImage = async (index: number) => {
		setLoading(true);
		try {
			const image = getImage(images[index].full);
			setMainSrc(image);
		} catch (error) {
			console.log(error);
		} finally {
			setLoading(false);
		}
	};
	const prevImage = (index: number) => {
		if (loading) {
			return;
		} else {
			const newIndex = (index + images.length - 1) % images.length;
			setSelectedIndex(newIndex);
			getMainImage(newIndex);
		}
	};
	const nextImage = (index: number) => {
		if (loading) {
			return;
		} else {
			const newIndex = (index + images.length + 1) % images.length;
			setSelectedIndex(newIndex);
			getMainImage(newIndex);
		}
	};

	const openImage = (index: number) => {
		if (isMobile) return;
		else {
			setIsOpen(true);
			setSelectedIndex(index);
			getMainImage(index);
			document.body.style.overflow = 'hidden';
		}
	};

	const closeImage = () => {
		setIsOpen(false);
		setSelectedIndex(undefined);
		document.body.style.overflow = 'auto';
	};

	useEffect(() => {
		const handleKeyPress = (event: KeyboardEvent) => {
			const keyName = event.key;
			if (event.defaultPrevented) {
				return; // Do nothing if event already handled
			}
			if (keyName === 'Escape') {
				closeImage();
			}
			if (keyName === 'ArrowLeft' && selectedIndex !== undefined) {
				prevImage(selectedIndex);
			}
			if (keyName === 'ArrowRight' && selectedIndex !== undefined) {
				nextImage(selectedIndex);
			}
		};
		if (isOpen) {
			document.addEventListener('keyup', handleKeyPress);
		}
		return () => {
			document.removeEventListener('keyup', handleKeyPress);
		};
	});

	function handleWindowSizeChange() {
		if (window.innerWidth <= 640) {
			setIsMobile(true);
		} else {
			setIsMobile(false);
		}
	}
	useEffect(() => {
		handleWindowSizeChange();
		window.addEventListener('resize', handleWindowSizeChange);
		return () => {
			window.removeEventListener('resize', handleWindowSizeChange);
		};
	}, []);

	return (
		<>
			<React.Fragment>
				{images.map((img, imgIndex) => {
					if (filter === 'all' ? filter : img.relativeDirectory === filter) {
						const thumbImage = getImage(img.full);
						if (!thumbImage) {
							return null;
						}

						return (
							<div key={imgIndex}>
								<div
									onClick={() => {
										openImage(imgIndex);
									}}
									className='md:h-[calc(33vw_*_0.66)]'
								>
									<GatsbyImage
										image={thumbImage}
										alt={img.thumbAlt || ''}
										objectFit='contain'
										className='md:h-[calc(33vw_*_0.66)] bg-white md:cursor-pointer md:hover:opacity-70 duration-500'
									/>
								</div>
							</div>
						);
					}
				})}
			</React.Fragment>
			{isOpen && mainSrc && !isMobile && (
				<div className='fixed top-0 left-0 w-screen h-screen bg-black overflow-hidden  z-40'>
					<div className='flex justify-center align-middle w-full h-full'>
						<GatsbyImage image={mainSrc} alt='' objectFit='contain' />
						<button
							onClick={closeImage}
							className='text-white w-10 h-10 z-50 absolute top-5 right-5  '
						>
							<span className='bg-black/50 rounded-lg px-2 py-1'>X</span>
						</button>
						{selectedIndex !== undefined && (
							<div className='text-white absolute bg-black/50 rounded-lg px-2 bottom-5 space-x-10 z-50 '>
								<button
									onClick={() => prevImage(selectedIndex)}
									className='p-1'
								>
									&#10094;
								</button>
								<button
									onClick={() => nextImage(selectedIndex)}
									className='p-1'
								>
									&#10095;
								</button>
							</div>
						)}
					</div>
				</div>
			)}
		</>
	);
};

export default Gallery;
