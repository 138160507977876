import * as React from 'react';
import { graphql, Link } from 'gatsby';
import { IGatsbyImageData } from 'gatsby-plugin-image';
import Gallery from '../components/Gallery';
import { useState } from 'react';

interface ImageSharpEdge {
	node: {
		childImageSharp: {
			thumb: IGatsbyImageData;
			full: IGatsbyImageData;
		};
		relativeDirectory: string;
	};
}

interface PageProps {
	data: {
		huarazPhotos: {
			edges: ImageSharpEdge[];
		};
		sacred_valleyPhotos: {
			edges: ImageSharpEdge[];
		};
		amazonPhotos: {
			edges: ImageSharpEdge[];
		};
		canadaPhotos: {
			edges: ImageSharpEdge[];
		};
		fuck_aboot_additionsPhotos: {
			edges: ImageSharpEdge[];
		};
		limaPhotos: {
			edges: ImageSharpEdge[];
		};
		doublePhotos: {
			edges: ImageSharpEdge[];
		};
		fuckPhotos: {
			edges: ImageSharpEdge[];
		};
		namPhotos: {
			edges: ImageSharpEdge[];
		};
	};
}

const IndexPage: React.FC<PageProps> = ({ data }) => {
	const imageGroups = [
		data.huarazPhotos,
		data.sacred_valleyPhotos,
		data.amazonPhotos,
		data.canadaPhotos,
		data.fuck_aboot_additionsPhotos,
		data.limaPhotos,
		data.doublePhotos,
		data.fuckPhotos,
		data.namPhotos,
	];
	const images: {
		caption: string;
		relativeDirectory: string;
		thumb: IGatsbyImageData;
		full: IGatsbyImageData;
	}[] = [];

	imageGroups.forEach((imageGroup) => {
		const groupImages = imageGroup.edges.map(({ node }, index) => ({
			...node.childImageSharp,
			caption: `Image ${index}`,
			relativeDirectory: node.relativeDirectory,
		}));
		images.push(...groupImages);
	});

	const [filter, setFilter] = useState('all');

	function scrollToTop() {
		window.scrollTo(0, 0);
	}

	return (
		<main>
			<div className='md:flex gap-x-2 items-center pb-2 sm:sticky top-0 left-0 bg-white z-30 w-full'>
				<Link to='/' className='bg-black text-white pl-20 text-3xl pr-1'>
					35mm
				</Link>
				<div className='space-x-1 md:space-x-5'>
					<button
						onClick={() => {
							setFilter('all');
							scrollToTop();
						}}
						className={`ml-1 md:ml-5 border px-2 mt-1 border-black ${
							filter === 'all'
								? 'bg-black text-white'
								: 'hover:bg-black hover:text-white duration-200'
						} `}
					>
						All
					</button>
					<button
						onClick={() => {
							setFilter('huaraz');
							scrollToTop();
						}}
						className={`border px-2 mt-1 border-black ${
							filter === 'huaraz'
								? 'bg-black text-white'
								: 'hover:bg-black hover:text-white duration-200'
						} `}
					>
						Huaraz
					</button>
					<button
						onClick={() => {
							setFilter('sacred_valley');
							scrollToTop();
						}}
						className={`border px-2 mt-1 border-black ${
							filter === 'sacred_valley'
								? 'bg-black text-white'
								: 'hover:bg-black hover:text-white duration-200'
						} `}
					>
						Sacred Valley
					</button>
					<button
						onClick={() => {
							setFilter('amazon');
							scrollToTop();
						}}
						className={`border px-2 mt-1 border-black ${
							filter === 'amazon'
								? 'bg-black text-white'
								: 'hover:bg-black hover:text-white duration-200'
						} `}
					>
						Amazon
					</button>
					<button
						onClick={() => {
							setFilter('canada');
							scrollToTop();
						}}
						className={`border px-2 mt-1 border-black ${
							filter === 'canada'
								? 'bg-black text-white'
								: 'hover:bg-black hover:text-white duration-200'
						} `}
					>
						Canada
					</button>
					<button
						onClick={() => {
							setFilter('fuck_aboot_additions');
							scrollToTop();
						}}
						className={`border px-2 mt-1 border-black ${
							filter === 'fuck_aboot_additions'
								? 'bg-black text-white'
								: 'hover:bg-black hover:text-white duration-200'
						} `}
					>
						Fuck Aboot Extended
					</button>
					<button
						onClick={() => {
							setFilter('lima');
							scrollToTop();
						}}
						className={`border px-2 mt-1 border-black ${
							filter === 'lima'
								? 'bg-black text-white'
								: 'hover:bg-black hover:text-white duration-200'
						} `}
					>
						lima
					</button>
					<button
						onClick={() => {
							setFilter('double');
							scrollToTop();
						}}
						className={`border px-2 mt-1 border-black ${
							filter === 'double'
								? 'bg-black text-white'
								: 'hover:bg-black hover:text-white duration-200'
						} `}
					>
						double exposure
					</button>
					<button
						onClick={() => {
							setFilter('fuck');
							scrollToTop();
						}}
						className={`border px-2 mt-1 border-black ${
							filter === 'fuck'
								? 'bg-black text-white'
								: 'hover:bg-black hover:text-white duration-200'
						} `}
					>
						fuck aboot and find oot
					</button>
					<button
						onClick={() => {
							setFilter('nam');
							scrollToTop();
						}}
						className={`border px-2 mt-1 border-black ${
							filter === 'nam'
								? 'bg-black text-white'
								: 'hover:bg-black hover:text-white duration-200'
						} `}
					>
						nam-china border
					</button>
				</div>
			</div>
			<div className='grid grid-cols-1 md:grid-cols-3 gap-1 my-2'>
				<Gallery images={images} filter={filter} />
			</div>
		</main>
	);
};

export default IndexPage;

export const PageQuery = graphql`
	query ImagesForGallery {
		huarazPhotos: allFile(
			filter: {
				extension: { regex: "/(jpg)|(png)|(jpeg)/" }
				relativeDirectory: { eq: "huaraz" }
			}
			sort: { fields: base, order: ASC }
		) {
			edges {
				node {
					id
					relativeDirectory
					base
					childImageSharp {
						thumb: gatsbyImageData(
							width: 270
							height: 270
							placeholder: DOMINANT_COLOR
						)
						full: gatsbyImageData(
							layout: CONSTRAINED
							placeholder: DOMINANT_COLOR
						)
					}
				}
			}
		}
		sacred_valleyPhotos: allFile(
			filter: {
				extension: { regex: "/(jpg)|(png)|(jpeg)/" }
				relativeDirectory: { eq: "sacred_valley" }
			}
			sort: { fields: base, order: ASC }
		) {
			edges {
				node {
					id
					relativeDirectory
					base
					childImageSharp {
						thumb: gatsbyImageData(
							width: 270
							height: 270
							placeholder: DOMINANT_COLOR
						)
						full: gatsbyImageData(
							layout: CONSTRAINED
							placeholder: DOMINANT_COLOR
						)
					}
				}
			}
		}
		amazonPhotos: allFile(
			filter: {
				extension: { regex: "/(jpg)|(png)|(jpeg)/" }
				relativeDirectory: { eq: "amazon" }
			}
			sort: { fields: base, order: ASC }
		) {
			edges {
				node {
					id
					relativeDirectory
					base
					childImageSharp {
						thumb: gatsbyImageData(
							width: 270
							height: 270
							placeholder: DOMINANT_COLOR
						)
						full: gatsbyImageData(
							layout: CONSTRAINED
							placeholder: DOMINANT_COLOR
						)
					}
				}
			}
		}
		canadaPhotos: allFile(
			filter: {
				extension: { regex: "/(jpg)|(png)|(jpeg)/" }
				relativeDirectory: { eq: "canada" }
			}
			sort: { fields: base, order: ASC }
		) {
			edges {
				node {
					id
					relativeDirectory
					base
					childImageSharp {
						thumb: gatsbyImageData(
							width: 270
							height: 270
							placeholder: DOMINANT_COLOR
						)
						full: gatsbyImageData(
							layout: CONSTRAINED
							placeholder: DOMINANT_COLOR
						)
					}
				}
			}
		}
		fuck_aboot_additionsPhotos: allFile(
			filter: {
				extension: { regex: "/(jpg)|(png)|(jpeg)/" }
				relativeDirectory: { eq: "fuck_aboot_additions" }
			}
			sort: { fields: base, order: ASC }
		) {
			edges {
				node {
					id
					relativeDirectory
					base
					childImageSharp {
						thumb: gatsbyImageData(
							width: 270
							height: 270
							placeholder: DOMINANT_COLOR
						)
						full: gatsbyImageData(
							layout: CONSTRAINED
							placeholder: DOMINANT_COLOR
						)
					}
				}
			}
		}
		limaPhotos: allFile(
			filter: {
				extension: { regex: "/(jpg)|(png)|(jpeg)/" }
				relativeDirectory: { eq: "lima" }
			}
			sort: { fields: base, order: ASC }
		) {
			edges {
				node {
					id
					relativeDirectory
					base
					childImageSharp {
						thumb: gatsbyImageData(
							width: 270
							height: 270
							placeholder: DOMINANT_COLOR
						)
						full: gatsbyImageData(
							layout: CONSTRAINED
							placeholder: DOMINANT_COLOR
						)
					}
				}
			}
		}
		doublePhotos: allFile(
			filter: {
				extension: { regex: "/(jpg)|(png)|(jpeg)/" }
				relativeDirectory: { eq: "double" }
			}
			sort: { fields: base, order: ASC }
		) {
			edges {
				node {
					id
					relativeDirectory
					base
					childImageSharp {
						thumb: gatsbyImageData(
							width: 270
							height: 270
							placeholder: DOMINANT_COLOR
						)
						full: gatsbyImageData(
							layout: CONSTRAINED
							placeholder: DOMINANT_COLOR
						)
					}
				}
			}
		}
		fuckPhotos: allFile(
			filter: {
				extension: { regex: "/(jpg)|(png)|(jpeg)/" }
				relativeDirectory: { eq: "fuck" }
			}
			sort: { fields: base, order: ASC }
		) {
			edges {
				node {
					id
					relativeDirectory
					base
					childImageSharp {
						thumb: gatsbyImageData(
							width: 270
							height: 270
							placeholder: DOMINANT_COLOR
						)
						full: gatsbyImageData(
							layout: CONSTRAINED
							placeholder: DOMINANT_COLOR
						)
					}
				}
			}
		}
		namPhotos: allFile(
			filter: {
				extension: { regex: "/(jpg)|(png)|(jpeg)/" }
				relativeDirectory: { eq: "nam" }
			}
			sort: { fields: base, order: ASC }
		) {
			edges {
				node {
					id
					relativeDirectory
					base
					childImageSharp {
						thumb: gatsbyImageData(
							width: 270
							height: 270
							placeholder: DOMINANT_COLOR
						)
						full: gatsbyImageData(
							layout: CONSTRAINED
							placeholder: DOMINANT_COLOR
						)
					}
				}
			}
		}
	}
`;
